/* eslint-disable */
import { stringify } from 'qs';
import axios from "axios";

const $axios = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
})

export const axiosInit = (JWT, LINKS) => {
    if (window.axiosInterceptorInitialized) {
        return;
    }
    window.axiosInterceptorInitialized = true;
    $axios.defaults.baseURL = LINKS.api;
    $axios.defaults.headers['Authorization'] = `Bearer ${JWT}`;

    $axios.interceptors.request.use((config) => {
        config.paramsSerializer = (params => stringify(params, { arrayFormat: 'brackets' }));
        return config;
    }, error => {
        throw error;
    });
};

export default $axios;