import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import BL_UI, { ToastWrapper } from "@bloglovin/vue-component-library";
import { axiosInit } from "@/utils/axios-service";
import debug from "@/utils/debugger";

import "@bloglovin/vue-component-library/style.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const MICROSERVICE_RENDER_EVENT_NAME = "render-blov-micro-ui";

function assertHasRequiredDatasetProps(dataset, properties, componentName) {
    if (!Array.isArray(properties)) {
        throw new Error(`requiredProps for ${componentName} is not of type Array`);
    }
    const datasetProperties = Object.keys(dataset);
    properties.forEach(property => {
        if (!datasetProperties.includes(property)) {
            // eslint-disable-next-line vue/max-len
            throw new Error(`requiredProp 'data-${property}' not found in '${componentName}' HTML dataset`);
        }
    });
}

// instantiate apps separately from mounting them (so we can use pinia before vue apps are rendered)
// register all entry points here pulling required props from the DOM
function refreshMicroUi(myMicroServiceComponentName, components, excludeList) {
    if (excludeList.includes(myMicroServiceComponentName)) {
        // eslint-disable-next-line vue/max-len
        debug.log(`micro service ${myMicroServiceComponentName} is ignoring render request, because it is on the exclude list`);
        return;
    }
    debug.log(`micro service ${myMicroServiceComponentName} is rendering`);
    let countOfRenderedElements = 0;
    components.forEach((component) => {
        const domSelector = component.domSelector;
        const domElements = document.querySelectorAll(`[blov-micro-ui='${domSelector}']`);
        // eslint-disable-next-line vue/max-len
        debug.log(`searching DOM for ${myMicroServiceComponentName}-${component.name}, selector:[blov-micro-ui='${domSelector}'] found: ${domElements.length}`);
        domElements.forEach(element => {
            let dataset = { ...element.dataset };
            assertHasRequiredDatasetProps(dataset, component.requiredProps, component.name);

            createApp(component.importedComponent, dataset)
                .use(pinia)
                .use(BL_UI)
                .mount(element);
            countOfRenderedElements++;
        });
    });
    if (countOfRenderedElements > 0) {
        // eslint-disable-next-line vue/max-len
        debug.log(`${myMicroServiceComponentName} is dispatching renderEvent: ${MICROSERVICE_RENDER_EVENT_NAME}`);
        const newExcludeList = [myMicroServiceComponentName];
        // eslint-disable-next-line vue/max-len
        const event = new CustomEvent(MICROSERVICE_RENDER_EVENT_NAME, { detail: { excludeList: newExcludeList } });
        window.dispatchEvent(event);
    }
}

export const init = (myMicroServiceComponentName, components, jwt, links) => {
    axiosInit(jwt, links);
    // eslint-disable-next-line vue/max-len
    debug.log(`micro service ${myMicroServiceComponentName} is listening for renderEvent: ${MICROSERVICE_RENDER_EVENT_NAME}`);
    // window.addEventListener(MICROSERVICE_RENDER_EVENT_NAME, (event) => {
    //     // eslint-disable-next-line vue/max-len
    //     debug.log(`micro service ${myMicroServiceComponentName} detected a renderEvent: ${MICROSERVICE_RENDER_EVENT_NAME}`);
    //     refreshMicroUi(myMicroServiceComponentName, components, event.detail.excludeList);
    // }, false);
    refreshMicroUi(myMicroServiceComponentName, components, []);
    if (!document.querySelector("[blov-micro-ui-toast]")) {
        debug.log("micro service global toast component not found. Creating...");
        let toastElement = document.createElement("div");
        toastElement.setAttribute("blov-micro-ui-toast", "");
        document.body.appendChild(toastElement);
        createApp(ToastWrapper)
            .use(pinia)
            .mount(toastElement);
    }
};