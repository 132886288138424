import { defineAsyncComponent } from "vue";
import { init } from "@/utils/micro-component";

const components = [
    {
        /**
         * @api {div} blov-micro-ui="Test" Test Component
         * @apiGroup Test
         *
         * @apiName Test Component
         * @apiDescription
         * Test component to validate rendering and API access
         *
         * @apiParam {string} data-prop1 Sample Prop 1
         * @apiParam {string} data-prop2 Sample Prop 2
         * @apiParam {string} [data-prop3] Optional Sample Prop 3
         * @apiParam {string} [data-prop4] Optional Sample Prop 4
         * @apiExample {html} Example Usage:
         *      <div
         *        blov-micro-ui="Test"
         *        data-prop1="Prop1"
         *        data-prop2="Prop2"
         *        data-prop4="Prop4"
         *      ></div>
         **/
        importedComponent: defineAsyncComponent(() => import("@/views/Test")),
        name: "Test",
        domSelector: "Test",
        requiredProps: [
            "prop1",
            "prop2",
        ],
        optionalProps: [
            "prop3",
            "prop4",
        ],
    },
    {
        importedComponent: defineAsyncComponent(() => import("@/views/ViewBrands")),
        name: "ViewBrands",
        domSelector: "ViewBrands",
        requiredProps: [],
        optionalProps: [],
    },
    {
        importedComponent: defineAsyncComponent(() => import("@/views/CreateBrand")),
        name: "CreateBrand",
        domSelector: "CreateBrand",
        requiredProps: [],
        optionalProps: []
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/UpdateBrand")),
        name: "UpdateBrand",
        domSelector: "UpdateBrand",
        requiredProps: [],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/ViewLinksByBrandId")),
        name: "ViewLinksByBrandId",
        domSelector: "ViewLinksByBrandId",
        requiredProps: [
            "affiliateNetworkId",
            "brandId"
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/AddBrandAffiliate")),
        name: "AddBrandAffiliate",
        domSelector: "AddBrandAffiliate",
        requiredProps: [
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/ViewLinksByUserId")),
        name: "ViewLinksByUserId",
        domSelector: "ViewLinksByUserId",
        requiredProps: [
            "affiliateNetworkId",
            "userId"
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/CreateLink")),
        name: "CreateLink",
        domSelector: "CreateLink",
        requiredProps: [
            "campaignId",
            "userId",
            "brandId"
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/CreateLinks")),
        name: "CreateLinks",
        domSelector: "CreateLinks",
        requiredProps: [
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/PublisherDomainsList")),
        name: "PublisherDomainsList",
        domSelector: "PublisherDomainsList",
        requiredProps: [
            "orgId",
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/AddPublisherDomain")),
        name: "AddPublisherDomain",
        domSelector: "AddPublisherDomain",
        requiredProps: [
            "orgId",
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/EditPublisherDomain")),
        name: "EditPublisherDomain",
        domSelector: "EditPublisherDomain",
        requiredProps: [
            "publisherDomainId",
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/BrandClickSummaryReport")),
        name: "BrandClickSummaryReport",
        domSelector: "BrandClickSummaryReport",
        requiredProps: ["brandOrgId"],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/UserClickSummaryReport")),
        name: "UserClickSummaryReport",
        domSelector: "UserClickSummaryReport",
        requiredProps: [
            "publisherOrgId"
        ],
        optionalProps: [],
    },
    {    
        importedComponent: defineAsyncComponent(() => import("@/views/ViewActionData")),
        name: "ViewActionData",
        domSelector: "ViewActionData",
        requiredProps: [
        ],
        optionalProps: [
            "page",
            "sortBy",
            "filterBy"
        ],
    }
];
const jwt = window.blclient.data.JWT;
init(process.env.PROJECT_NAME, components, jwt, window.blclient.data.links);